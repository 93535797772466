/* Style the header with a grey background and some padding */
html {
  background-color: #FFFDFA;
}



body { 
  /*margin: 0;*/
  font-family: Arial, Helvetica, sans-serif;
  
}

@keyframes fadeInAnimation {
  0% {
      transform: translate3d(-50px, 0, 0);
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

@keyframes fadeInAnimation3 {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

@keyframes fadeInAnimation2 {
  0% {
      transform: translate3d(50px, 0, 0);
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

.titleanim {
  opacity: 0;
  transform: translate3d(0, 0, 0);
  /*animation: fadeInAnimation2 ease 3.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;*/
  -webkit-animation: 3s ease 0.5s normal forwards 1 fadeInAnimation2;
  animation: 3s ease 0.5s normal forwards 1 fadeInAnimation2;
}

.header {
  overflow: hidden;
  max-width: 100%;
  padding: 20px 10px;
}

.header-mobile {
  overflow: hidden;
  width: 100%;
  padding: 20px 10px;
}

.normalHeader {
  text-align: center;
    vertical-align: middle;
    font-family: Courier;
    color: rgb(0, 0, 0);
    font-size:40px;
}

/* Style the header links */
.header a {
  float: left;
  color: black;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
  cursor: pointer;
}

.header-mobile a {
  float: left;
  color: black;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
}
.header form {
  float: left;
  color: black;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
}
.header-mobile form {
  float: left;
  color: black;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
  z-index: 100;
}

/* Style the logo link (notice that we set the same value of line-height and font-size to prevent the header to increase when the font gets bigger */
.header a.logo {
  font-size: 25px;
  font-weight: bold;
}
.header-mobile a.logo {
  font-size: 25px;
  font-weight: bold;
}

/* Change the background color on mouse-over */
.header a:hover {
  background-color: #ddd;
  color: black;
}
.header-mobile a:hover {
  background-color: #ddd;
  color: black;
}

/* Style the active/current link*/
.header a.active {
  background-color: dodgerblue;
  color: white;
}

.active {
  background-color: rgba(32, 131, 53, 0.61) !important;
  color: white !important;
}
.header-mobile a.active {
  background-color: dodgerblue;
  color: white;
}

/* Float the link section to the right */
.header-right {
  float: right;
}
.header-right-mobile {
  float: right;
}

/* Add media queries for responsiveness - when the screen is 500px wide or less, stack the links on top of each other */
@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }
  .header-right {
    float: none;
  }
  .flexcontainer {
    display: block;
    gap: 10px;
  }
}

footer {
  text-align: left;
  padding: 3px;
  color: white;
}


.mainphoto {
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
    background-image:   url(./lolss.jpg);
    background-size: cover;
    background-position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #ddd;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    min-height: 400px;
}

.aqichart {
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
    background-image:   url(./aqichart.png);
    background-size: cover;
    background-position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #ddd;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    min-height: 580px;
}

.aqiphoto {
  display: flex;
  background-image:   url(./aqichart.png);
  background-size: cover;
  background-position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  width: 200px;
  height: 300px;
}










.overcast {
  background-image:   url(./weathericons/overcast.png);
  background-size: cover;
  background-position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  height: 50px;
  width: 65px;
}
.partlycloudy {
  background-image:   url(./weathericons/partlysunny.png);
  background-size: cover;
  background-position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  height: 50px;
  width: 65px;
}
.fog {
  background-image:   url(./weathericons/fog.png);
  background-size: cover;
  background-position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  height: 50px;
  width: 65px;
}

.clear {
  background-image:   url(./weathericons/sunny.png);
  background-size: cover;
  background-position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  height: 50px;
  width: 50px;
}
.snowlight {
  background-image:   url(./weathericons/snowlight.png);
  background-size: cover;
  background-position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  height: 50px;
  width: 65px;
}
.snowheavy {
  background-image:   url(./weathericons/snowheavy.png);
  background-size: cover;
  background-position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  height: 50px;
  width: 65px;
}
.rainlight {
  background-image:   url(./weathericons/rainlight.png);
  background-size: cover;
  background-position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  height: 50px;
  width: 65px;
}
.rainheavy {
  background-image:   url(./weathericons/rainheavy.png);
  background-size: cover;
  background-position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  height: 50px;
  width: 65px;
}
.rainthunder {
  background-image:   url(./weathericons/rainthunder.png);
  background-size: cover;
  background-position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  height: 50px;
  width: 65px;
}









.overlay {
  position: absolute;
  height: 400px;
  width: 100%;
  background: linear-gradient(0,rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
  border-radius: 10px;
}

.overlayPermit-mobile {
  position: absolute;
  height: 200px;
  width: 100%;
  background: linear-gradient(0,rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
  border-radius: 10px;
}

.overlaylogin {
  position: absolute;
  height: 400px;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.overlay-mobile {
  position: absolute;
  height: 300px;
  width: 100%;
  background: linear-gradient(0,rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
  border-radius: 10px;
}

.overlayPermit {
  position: absolute;
  height: 200px;
  width: 85%;
  background: linear-gradient(0,rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
  border-radius: 10px;
}

.mainphoto-mobile {
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
    background-image:   url(./lolss.jpg);
    background-size: cover;
    background-position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #ddd;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    min-height: 300px;
}
.MuiPaper-elevation1 {
  box-shadow: rgba(0, 0, 0, 0.151) 0px 1px 3px, rgba(0, 0, 0, 0.046) 0px 1px 2px !important;
  background-color: rgba(255, 255, 255, 0.801) !important;
  text-align: left;
}

.alignleft {
  text-align: center !important;
}

.goalphoto {
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  
  flex-direction: column;
    background-image:   url(./mainviewcpy.jpg);
    background-size: cover;
    background-position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #ddd;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
    
    border-radius: 10px;
    width: 100%;
    min-height: 200px;
    max-height: 200px;
}

.goalphoto-mobile {
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  
  flex-direction: column;
    background-image:   url(./goalbackground.jpg);
    background-size: cover;
    background-position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #ddd;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
    
    border-radius: 8px;
    width: 100%;
    min-height: 200px;
    max-height: 200px;
}

.goalphoto p {
  z-index: 1;
  text-align: center;
  vertical-align: middle;
  padding: 70px 0;
  font-family: Courier;
  /*font-family:Georgia, 'Times New Roman', Times, serif;*/
  letter-spacing: 0.2rem;
  color: rgb(255, 255, 255);
  font-size:50px;
  
}

.goalphoto-mobile p {
  z-index: 1;
  text-align: center;
  vertical-align: middle;
  padding: 56px;
  font-family: Courier;
  color: rgb(255, 255, 255);
  font-size:40px;
}


.mainphoto p {
    z-index: 1;
    text-align: center;
    vertical-align: middle;
    margin-top: 50px;
    font-family: Courier;
    /*font-family:Georgia, 'Times New Roman', Times, serif;*/
    letter-spacing: 0.2rem;
    color: rgb(255, 255, 255);
    font-size:45px;
}
.mainphoto-mobile p {
  z-index: 1;
  text-align: center;
  vertical-align: middle;
  margin-top: 50px;
  font-family: Courier;
  color: rgb(255, 255, 255);
  font-size:30px;
}

.mainphoto a {
  text-align: center;
  vertical-align: middle;
  padding-top: 10px;
  padding-bottom: 100px;
  font-family: Courier;
  /*font-family:Georgia, 'Times New Roman', Times, serif;*/
  letter-spacing: 0.2rem;
  color: rgb(255, 255, 255);
  font-size:30px;
}
.mainphoto-mobile a {
  z-index: 1;
  text-align: center;
  vertical-align: middle;
  padding-top: 10px;
  padding-bottom: 100px;
  font-family: Courier;
  color: rgb(255, 255, 255);
  font-size:30px;
}

.btn2 {
  display: inline-block;
  outline: 0;
  appearance: none;
  padding: 0px 12px;
  margin-bottom: 10px;
  border: 0px solid transparent;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  background-color: rgb(9, 128, 76);
  box-shadow: rgb(19 170 82 / 40%) 0px 2px 3px;
  color: rgb(255, 255, 255);
  font-size: 30px;
  height: 50px;
  width: 100%;
  transition: all 150ms ease-in-out 0s;
}
.btn2:hover {
      color: rgb(255, 255, 255);
      background-color: rgb(17, 97, 73);
      box-shadow: rgb(19 170 82 / 40%) 0px 2px 3px, rgb(195 231 202) 0px 0px 0px 3px;
  }

  

  .btn3 {

    display: inline-block;
    outline: 0;
    appearance: none;
    padding: 0px 12px;
    border: 0px solid transparent;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    background-color: rgb(9, 128, 76);
    box-shadow: rgb(19 170 82 / 40%) 0px 2px 3px;
    color: rgb(255, 255, 255);
    font-size: 25px;
    font-weight: bold;
    height: 60px;
    width: 100%;
    max-width: 1100px;
    transition: all 150ms ease-in-out 0s;
  }
  .btn3:hover {
        color: rgb(255, 255, 255);
        background-color: rgb(17, 97, 73);
        box-shadow: rgb(19 170 82 / 40%) 0px 2px 3px, rgb(195 231 202) 0px 0px 0px 3px !important;
    }

    .btn3:disabled{
      color: rgb(255, 255, 255);
      background-color: rgb(124, 124, 124);
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 3px, rgba(139, 139, 139, 0.13) 0px 0px 0px 3px !important;
  }



  .container {
    z-index: 1;
    transform: translate(-50%, -50%);
  }

  .flexcenter {
    justify-content: "center";
    margin: auto;
    text-align: center;
  }

  .flexcontainer {
    display: flex;
	  justify-content: center;
    gap: 10px;
    margin-bottom:30px;
    
  }
  .flexweathermain {
    width:100%;
    display: flex;
	  justify-content: center;
    margin-bottom:30px;
    
  }
  .flexcontainerweather2 {
	  justify-content: center;
    text-align: center;
    gap: 10px;
    margin-bottom:30px;
  }
  .weathericon {
    position: relative;
    justify-content: center;
    margin:auto;
    width: 64px;
  }
  .weathericon-mobile {
    position: relative;
    justify-content: center;
    margin:auto;
    width: 64px;
  }
  .weathername{
    color:#3a46f3;
    height: 30px;
    background-color:#ddddddec;
  }
  .weathernamesmall{
    color:#3a46f3;
    height: 20px;
    font-size: 17px !important;
    background-color:#ddddddec;
  }
  

  .weathertitle {
    font-size: 20px;
    padding-top: 20px;
    width: 200px;
  }

  .weathertitlemobile {
    font-size: 20px;
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
  }

  .flexcontainerweather {
    box-shadow: rgba(0, 0, 0, 0.267) 0px 1px 3px, rgba(0, 0, 0, 0.046) 0px 1px 2px;
    background-color:#7d7d7d;
    border-radius: 20px;
    width: auto;
    display: flex;
    height: auto;
    gap: 10px;
    margin-bottom:30px;
    padding-right: 19px;
  }
  .flexcontainerweather-mobile {
    box-shadow: rgba(0, 0, 0, 0.267) 0px 1px 3px, rgba(0, 0, 0, 0.046) 0px 1px 2px;    background-color:#7d7d7d;
    border-radius: 20px;
    justify-content: center;
    text-align: center;
    height: auto;
    width:100%;
    gap: 10px;
    margin-bottom:30px;
  }

  .flexcontainerweather div {
    color:white
  }
  .flexcontainerweather-mobile div {
    color:white
  }
  .interiorweather {
    width: 120px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color:#4242425d;
  }
  .weathernamemobile {
    color:white;
  }
  .interiorweathertoday {
    width: 200px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color:#4242425d;
  }
  .interiorweathermobile{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      color:white;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      background: rgb(9, 0, 179);
      background: linear-gradient(183deg, rgba(9, 0, 179, 0.925) 0%, rgba(53,93,185,1) 33%, rgba(0,212,255,1) 100%);
      margin-bottom: 5px;
  }
  .hi {
    font-weight: bold;
    font-size: 20px;
  }
  .weathergriditem{
    color:white;
    display: flex;
    align-items: center;
  }
  .weathergridmain{
    padding: 10px;
  }


  .flexcontainer2 {
    display: flex;
	  justify-content: center;
    gap: 10px;
  }
   .flexcontainer-mobile {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  
  }

  .MuiAccordionSummary-content {
    display: flex !important;
	  justify-content: center !important;
    width: 100%;
  
  }
  .panel1a-content {
    justify-content: center !important;
  }
  .flexcontainer2-mobile {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .centerwelcome {
    opacity: 0;
    width: 100px;
    height: 60px;
    position: relative;
    left:9.5px;
    -webkit-animation: 3s ease 0.5s normal forwards 1 fadeInAnimation3;
    animation: 3s ease 0.5s normal forwards 1 fadeInAnimation3;
  }
  .centerlogin {
    opacity: 0;
    width: 100px;
    height: 60px;
    padding-top: 20px;
    position: relative;
    left:55.3%;
    -webkit-animation: 3s ease 0.5s normal forwards 1 fadeInAnimation3;
    animation: 3s ease 0.5s normal forwards 1 fadeInAnimation3;
  }
  .btn {
    font-family: Courier;
    width: 180px;
    height: 60px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #ffffff;
    outline: none;
    transition: 1s ease-in-out;
  }
  .btnlog {
    font-family: Courier;
    width: 90px;
    height: 30px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #ffffff;
    outline: none;
    transition: 1s ease-in-out;
  }
  .btn-mobile {
    font-family: Courier;
    color: #FFF;
    font-size: 20px;
    width: 180px;
    height: 60px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #ffffff;
    outline: none;
    transition: 1s ease-in-out;
  }
  
  .btn-mobile svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 150 480;
    stroke-dashoffset: 150;
    transition: 1s ease-in-out;
  }
  .btn svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 150 480;
    stroke-dashoffset: 150;
    transition: 1s ease-in-out;
  }
  
  .btn:hover {
    transition: 1s ease-in-out !important;
    background: #69d85d6f !important;
  }
  
  .btn:hover svg {
    stroke-dashoffset: -480 !important;
  }
  
  .btn span {
    color: white !important;
    font-size: 18px !important;
    font-weight: 100 !important;
  }



  .btn-mobile:hover {
    transition: 1s ease-in-out !important;
    background: #69d85d6f !important;
  }
  
  .btn-mobile svg {
    stroke-dashoffset: -480 !important;
  }
  
  .btn-mobile span {
    color: white !important;
    font-size: 18px !important;
    font-weight: 100 !important;
  }



  
  .btnlog:hover {
    transition: 1s ease-in-out !important;
    background: #69d85d6f !important;
  }
  
  .btnlog:hover svg {
    stroke-dashoffset: -480 !important;
  }
  
  .btnlog span {
    color: white !important;
    font-size: 18px !important;
    font-weight: 100 !important;
  }





  /*OLD STUFF*/

  

  .goals {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
  
  .goal {
    background-color: #f4f4f4;
    margin: 10px 0;
    padding: 20px 0 10px;
    position: relative;
    background: rgb(177, 238, 192, 0.3)
  }
  
  .goal .close {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    border: none;
    background: none;
  }

  .good{
    background-color:#018a0c;
    color: white;
    text-align: center;
  }
  .margingood {
    margin-top:15px;
  }

  .moderate{
    background-color:#b4a800;
    color: white;
    text-align: center;
  }

  .unhealthysens{
    background-color:#ff7300;
    color: white;
    text-align: center;
  }

  .unhealthy{
    background-color:#fd3b00;
    color: white;
    text-align: center;
  }
  .vunhealthy{
    background-color:#df00fd;
    color: white;
    text-align: center;
  }
  .hazardous{
    background-color:#74002c;
    color: white;
    text-align: center;
  }

  .flexweather-mobile{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    border-radius: 10px;
    background-color: #000b69;
    color: white;
  }
  

  .formWrapper{
    border: 10px solid #040704;
    border-radius: 24px;
    border: rgba(0, 0, 0, 0.5);
  }
  
  .form{
    display: table;
    justify-content: center;
    width: 80%;
    text-align: center;
    margin: auto;
    align-items: center;
  }
  .content {
    min-height: 650px;
    display: table;
    justify-content: center;
    width: 80%;
    text-align: center;
    margin: auto;
    align-items: center;
  }

  .weathercontents {
    min-height: 650px;
    display: table;
    justify-content: center;
    width: 100%;
    text-align: center;
    margin: auto;
    align-items: center;
  }
  .contentform {
    display: table;
    justify-content: center;
    width: 80%;
    text-align: center;
    margin: auto;
    align-items: center;
  }
  .multiwrap {
    max-width:600px;
    margin:auto;
  }
  .goalFormWrapper {
    display: grid;
	  justify-content: center;
    gap: 10px;
  }
  .grid-container {
    box-shadow: rgba(0, 0, 0, 0.151) 0px 1px 3px, rgba(0, 0, 0, 0.046) 0px 1px 2px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: rgba(255, 255, 255, 0.801);
    padding: 20px;
    border-radius: 10px;
    position: relative;
  }
  .grid-container-mobile {
    box-shadow: rgba(0, 0, 0, 0.055) 0px 1px 3px, rgba(0, 0, 0, 0.046) 0px 1px 2px;
    background-color: rgba(255, 255, 255, 0.623);
    padding: 10px;
    border-radius: 10px;
    position: relative;
    
  }
 

  .grid-item {
    padding: 20px;
    margin: auto;
    text-align: center;
    justify-content: center;
  }
  .bordergrey {
    position: absolute;
    left: 50%;
    width: 2px;
    height: 100%;
    background: rgba(144, 144, 144, 0.111);
    right: -9px
  }
  .bordergrey-mobile {
  }

  .bordermobile {
    position: absolute;
    left:25%;
    width: 50%;
    height: 2px;
    background: rgba(144, 144, 144, 0.322);
  }
  
  .calendar {
    margin: auto;
    border: 3px solid black;
    padding: 10px;
    margin-bottom: 10px;
  }

  .form-group {
    padding-top: 20px;
    margin: auto;
    max-width: 300px;
  }
  
  .form-group input,
  .form-group textarea,
  .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    margin-bottom: 10px;
    font-family: inherit;
  }
  
  .form-group label {
    text-align: left;
    display: block;
    margin: 0 0 5px 3px;
  }

  .backgroundDiv2{
    margin: auto;
    text-align: center;
    border-radius: 20px;
    /*background-image:   url(./aniket-deole-M6XC789HLe8-unsplash.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;*/
    width: 85%;

  }
  .backgroundDiv2-mobile{
    margin: auto;
    text-align: center;
    border-radius: 20px;
    /*background-image:   url(./aniket-deole-M6XC789HLe8-unsplash.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;*/

  }

  .backgroundDiv{
    max-width: 960px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    margin: auto;
    position:relative;
  
  }
  .unset * {
    all: initial;
  }
  
  /*div label input {
    margin-right:100px;
  } FIX LATER---------------------------------------------------------------------------*/

  #bgwrapper {
    background-size: cover;
    background-position: center center;
    min-height: 100%;
    min-width: 1024px;
    width:100%;
    height: auto;
    position: relative;
    background-attachment: fixed;
}

#ck-button:hover {
  background:red;
}

#ck-button label {
  float:left;
  width:4.0em;
}

#ck-button label span {
  text-align:center;
  padding:3px 0px;
  display:block;
}

#ck-button label input {
  position:absolute;
  top:-20px;
}

#ck-button input:checked + span {
  background-color:#911;
  color:#fff;
}





  /*OLD STUFF*/












  /* CARD ONLY ------------------------------------------------------*/

/*main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  font-family: 'Roboto', sans-serif;
}*/

.card {
  width: 13rem;
  height: 21rem;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  color: rgb(240, 240, 240);
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
}
.card-mobile {
  width: 100%;
  height: 10rem;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  color: rgb(240, 240, 240);
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}
.card img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .card-mobile img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

.card h2 {
    font-size: 20px;
    position: absolute;
    inset: auto auto 30px 15px;
    margin: 0;
    transition: inset .3s .3s ease-out;
    font-weight: normal;
    text-transform: uppercase;
  }
  .card-mobile h2 {
    position: absolute;
    inset: auto auto 30px 30px;
    margin: 0;
    transition: inset .3s .3s ease-out;
    font-weight: normal;
    text-transform: uppercase;
  }
  
  .card p, .card a {
    position: absolute;
    opacity: 0;
    max-width: 80%;
    transition: opacity .3s ease-out;
  }
  .card-mobile p, .card-mobile a {
    position: absolute;
    opacity: 0;
    max-width: 80%;
    transition: opacity .3s ease-out;
  }
  
  .card p {
    inset: auto auto 80px 30px;
  }
  .card-mobile p {
    inset: auto auto 80px 30px;
  }
  
  .card a {
    inset: auto auto 40px 30px;
    color: inherit;
    text-decoration: none;
  }
  .card-mobile a {
    inset: auto auto 40px 30px;
    color: inherit;
    text-decoration: none;
  }
  
  .card:hover h2 {
    inset: auto auto 220px 15px;
    transition: inset .3s ease-out;
  }
  
  .card:hover p, .card:hover a {
    opacity: 1;
    transition: opacity .5s .1s ease-in;
  }
  
  .card:hover img {
    transition: opacity .3s ease-in;
    opacity: 1;
  }

  .card-mobile:hover h2 {
    inset: auto auto 100px 30px;
    transition: inset .3s ease-out;
  }
  
  .card-mobile:hover p, .card-mobile:hover a {
    opacity: 1;
    transition: opacity .5s .1s ease-in;
  }
  
  .card-mobile:hover img {
    transition: opacity .3s ease-in;
    opacity: 1;
  }

.material-symbols-outlined {
  vertical-align: middle;
}

  /* CARD ONLY ------------------------------------------------------*/

  /* 
    ================================
        Best Viewed In Full Page
    ================================
*/

/* Hover over a card to flip, can tab too. */

@import url('https://fonts.googleapis.com/css?family=Lato');

/* default */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* body */



@-webkit-keyframes fadeout {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
  
@keyframes fadeout {
  0% { opacity: 1; }
  100% { opacity: 0; }
}





/* .flip-card-container */
.flip-card-container {
  --hue: 150;
  --primary: hsl(var(--hue), 50%, 50%);
  --white-1: hsl(0, 0%, 90%);
  --white-2: hsl(0, 0%, 80%);
  --dark: hsl(var(--hue), 25%, 10%);
  --grey: hsl(0, 100%, 51%);
  
  width: 310px;
  height: 500px;
  margin: 10px;

  perspective: 1000px;

  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}

.emptyspace {
  min-height: 310px;
  min-width: 400px;
  border: 2px black solid;
}

.flip-card-container-fadeout {
  --hue: 150;
  --primary: hsl(var(--hue), 50%, 50%);
  --white-1: hsl(0, 0%, 90%);
  --white-2: hsl(0, 0%, 80%);
  --dark: hsl(var(--hue), 25%, 10%);
  --grey: hsl(0, 100%, 51%);
  
  width: 310px;
  height: 500px;
  margin: 10px;

  perspective: 1000px;

  -webkit-animation: fadeout 1s linear forwards;
  animation: fadeout 1s linear forwards;
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
}
}

/* .flip-card */
.flip-card {
  width: inherit;
  height: inherit;

  position: relative;
  transform-style: preserve-3d;
  transition: .6s .1s;
}

/* hover and focus-within states */
.flip-card-container:hover .flip-card,
.flip-card-container:focus-within .flip-card {
  transform: rotateY(180deg);
}

/* .card-... */
.card-front,
.card-back {
  width: 100%;
  height: 100%;
  border-radius: 24px;

  background: var(--dark);
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;

  backface-visibility: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}

/* .card-front */
.card-front {
  transform: rotateY(0deg);
  z-index: 2;
}

/* .card-back */
.card-back {
  transform: rotateY(180deg);
  z-index: 1;
}



/* figure */
figure {
  z-index: -1;
}

/* figure, .img-bg */
figure,
.img-bg {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.logo {
  color: rgb(0, 0, 0);
}

.loginHeader{
  padding: 30px;
}

/* img */
/*img {
  height: 100%;
  border-radius: 24px;
}*/

/* figcaption */
figcaption {
  display: block;

  width: auto;
  margin-top: 3%;
  padding: 8px 22px;

  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 2px;
  word-spacing: 6px;
  text-align: right;

  position: absolute;
  top: 0;
  right: 12px;

  color: var(--white-1);
  background: hsla(var(--hue), 25%, 10%, .5);
}

/* .img-bg */
.img-bg {
  background: hsla(var(--hue), 25%, 10%, .5);
}

.card-front .img-bg {
  clip-path: polygon(0 20%, 100% 40%, 100% 100%, 0 100%);
}

.card-front .img-bg::before {
  content: "";
  
  position: absolute;
  top: 34%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(18deg);

  width: 100%;
  height: 6px;
  border: 1px solid var(--primary);
  border-left-color: transparent;
  border-right-color: transparent;

  transition: .1s;
}

.card-back .img-bg {
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 60%);
}

/* hover state */
.flip-card-container:hover .card-front .img-bg::before {
  width: 6px;
  border-left-color: var(--primary);
  border-right-color: var(--primary);
}

/* ul */
.cardul {
  padding-top: 0% !important;
  margin: 0 auto !important;
  width: 70% !important;
  height: 100% !important;

  list-style: none !important;
  color: var(--white-1) !important;

  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}

/* li */
.cardli {
  width: 100% !important;
  margin-top: 12px !important;
  padding-bottom: 12px !important;

  font-size: 14px !important;
  text-align: center !important;

  position: relative !important;
}

.cardli:nth-child(2n) {
  color: var(--white-2) !important;
}

.cardli:not(:last-child)::after {
  content: "" !important;

  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;

  width: 100% !important;
  height: 1px !important;

  background: currentColor !important;
  opacity: .2 !important;
}

/* button */
.card-back button {
  font-family: inherit;
  font-weight: bold;
  color: var(--white-1);

  letter-spacing: 2px;

  padding: 9px 20px;
  border: 1px solid var(--grey);
  border-radius: 1000px;
  background: transparent;
  transition: .3s;

  cursor: pointer;
}


.card-back button:hover,
.card-back button:focus {
  color: var(--primary);
  background: hsla(var(--hue), 25%, 10%, .2);
  border-color: currentColor;
}


.card-back button:active {
  transform: translate(2px);
}

/* .design-container */
.design-container {
  --tr: 90;
  --op: .5;

  width: 100%;
  height: 100%;

  background: transparent;
  position: absolute;
  top: 0;
  left: 0;

  pointer-events: none;
}

.messagebox {
  font-size: 18px;
  font-family: inherit;
  resize: none;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid rgba(32, 122, 32, 0.37);
}
.messagebox:focus { outline: 1px solid rgb(17, 143, 44); }

/* .design */
.design {
  display: block;

  background: var(--grey);
  position: absolute;

  opacity: var(--op);
  transition: .3s;
}

.design--1,
.design--2,
.design--3,
.design--4 {
  width: 1px;
  height: 100%;
}

.design--1,
.design--2 {
  top: 0;
  transform: translateY(calc((var(--tr) - (var(--tr) * 2)) * 1%))
}

.design--1 {
  left: 20%;
}

.design--2 {
  left: 80%;
}

.design--3,
.design--4 {
  bottom: 0;
  transform: translateY(calc((var(--tr) + (var(--tr) - var(--tr))) * 1%))
}

.design--3 {
  left: 24%;
}

.design--4 {
  left: 76%;
}

.design--5,
.design--6,
.design--7,
.design--8 {
  width: 100%;
  height: 1px;
}

.design--5,
.design--6 {
  left: 0;
  transform: translateX(calc((var(--tr) - (var(--tr) * 2)) * 1%));
}

.design--5 {
  top: 41%;
}

.design--6 {
  top: 59%;
}

.design--7,
.design--8 {
  right: 0;
  transform: translateX(calc((var(--tr) + (var(--tr) - var(--tr))) * 1%))
}

.design--7 {
  top: 44%;
}

.design--8 {
  top: 56%;
}

/* states */
.card-back button:hover+.design-container,
.card-back button:active+.design-container,
.card-back button:focus+.design-container {
  --tr: 20 !important;
  --op: .7 !important;
}

.abs-site-link {
  position: fixed;
  bottom: 20px;
  left: 20px;
  color: hsla(0, 0%, 100%, .6);
  font-size: 16px;
  font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, sans-serif;
}

.pinfo {
  text-align: center;
}


/*FORMS*-----------------------------------------------------------------------------------*/

.formcard {
	margin: 2rem auto;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 425px;
	background-color: rgba(255, 255, 255, 0.801);
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.267) 0px 1px 3px, rgba(0, 0, 0, 0.046) 0px 1px 2px;
	padding: .75rem;
}

.formcard-image {
	border-radius: 8px;
	overflow: hidden;
	padding-bottom: 65%;
	background-image: url('./tent5.jpg');
  background-size: cover;
	background-repeat: no-repeat;
	background-position: 0 5%;
	position: relative;
  
}

.card-heading {
	position: absolute;
	left: 10%;
	top: 15%;
	right: 10%;
	font-size: 1.75rem;
	font-weight: 700;
	color: #ffffff;
	line-height: 1.222;
}
.card-heading small {
		display: block;
		font-size: .75em;
		font-weight: 400;
		margin-top: .25em;
	}

  p {
    padding: 4px;
    color: rgb(149, 149, 149);
    
  }
  .recgov {
    color: rgba(0, 0, 0, 0.699);
    
  }
  .warning {
    padding: 4px;
    color: rgba(255, 0, 0, 0.582);
    
  }
  .data {
    color: rgba(18, 131, 35, 0.935);
  }

h3 {
  padding:20px;
}
h4 {
  text-align: center;
  font-size: 30px;
  color:rgba(0, 0, 0, 0.755);
  padding: 20px;
}

h5 {
  padding: 30px 0 8px;
  text-align: center;
  position: relative;
  color:rgba(0, 0, 0, 0.755);
  font-size:40px;
}

h5::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 10px;
  width: 270px;
  height: 14px;
  transform: skew(-12deg) translateX(-54%);
  background: rgba(41, 122, 25, 0.463);
  z-index: -1;
}


.grid-container-howitworks {
  box-shadow: rgba(0, 0, 0, 0.055) 0px 1px 3px, rgba(0, 0, 0, 0.046) 0px 1px 2px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: rgba(255, 255, 255, 1);
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.grid-container-howitworks-mobile {
  box-shadow: rgba(0, 0, 0, 0.055) 0px 1px 3px, rgba(0, 0, 0, 0.046) 0px 1px 2px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  position: relative;
}

.grid-item-howitworks {
  padding: 20px;

  text-align: center;
  justify-content: center;
}






.btn4 {
  display: inline-block;
  outline: 0;
  vertical-align:middle;
  appearance: none;
  padding: 0px 12px;
  border: 0px solid transparent;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  background-color: rgba(9, 128, 76, 0.479);
  box-shadow: rgb(19 170 82 / 40%) 0px 2px 3px;
  color: rgb(255, 255, 255);
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 14px;
  font-weight: 400;
  height: 36px;
  width: 100%;
  transition: all 150ms ease-in-out 0s;
}
.btn4:hover {
      color: rgb(255, 255, 255);
      background-color: rgb(17, 97, 73);
      box-shadow: rgb(19 170 82 / 40%) 0px 2px 3px, rgb(195 231 202) 0px 0px 0px 3px;
  }

.btn4:active {
    color: rgb(255, 255, 255);
    background-color: rgb(17, 97, 73);
    box-shadow: rgb(19 170 82 / 40%) 0px 2px 3px, rgb(195 231 202) 0px 0px 0px 3px;
}


/*FORMS*-----------------------------------------------------------------------------------*/


.rmsc {
  --rmsc-main: #018a0c !important; 
  --rmsc-hover: #2fae447f !important;
  --rmsc-selected: #307e4385 !important;
  --rmsc-border: #ccc !important;
  --rmsc-gray: #aaa !important;
  --rmsc-bg: #fff !important;
  --rmsc-p: 10px !important; /* Spacing */
  --rmsc-radius: 4px !important; /* Radius */
  --rmsc-h: 38px !important; /* Height */
  max-width: 400px;
  min-width: 400px;
  margin:auto;
}

.rmsc-mobile {
  --rmsc-main: #018a0c !important; 
  --rmsc-hover: #4e9853a9 !important;
  --rmsc-selected: #307e44be !important;
  --rmsc-border: #ccc !important;
  --rmsc-gray: #aaa !important;
  --rmsc-bg: #ffffff !important;
  --rmsc-p: 10px !important; /* Spacing */
  --rmsc-radius: 4px !important; /* Radius */
  --rmsc-h: 38px !important; /* Height */
  max-width: 250px;
  min-width: 250px;
  vertical-align: middle;
}


nav {
  height: 65px;
}


#menuToggle {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 25px;
  left: 25px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}


#menuToggle input
{
  display: flex;
  width: 40px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

#menuToggle span
{
  display: flex;
  width: 29.9px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #158a1f;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}


#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
  background: #36383F;
}
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

#menu
{
  display: flex;
  flex-direction: column;
  text-align:right;
  position: absolute;
  width: 330px;
  height: 400px;
  box-shadow: 0 0 10px #85888C;
  left: -90px;
  padding: 50px;
  padding-top: 70px;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu li
{
  padding: 10px 0;
  transition-delay: 2s;
}

#menuToggle input:checked ~ ul
{
  transform: none;
}

.nava {
  text-decoration: none;
  color: #1E1E23;
  opacity:1;
  font-size: 1.8em;
  font-weight: 400;
  transition: 200ms;
  margin-bottom: 10px;
}
.nava a:hover {
  opacity:0.5;
}
.navul {
  padding: 0;
  list-style-type: none;
}

.bordermobile2 {
  position:absolute;
  width: 100%;
  height: 2px;
  background: rgba(144, 144, 144, 0.322);
}

.flexnav {
  background-color: #FFFDFA;
  z-index: 100;
  position: sticky; top: 0;
  display: flex;
	justify-content: left;
}

h6 {
  position: absolute;
  left: 90px;
  font-size: 30px;
  color:rgba(0, 0, 0, 0.755);
  padding: 20px;
}

.button-wrap {
  position: relative;
  text-align: center;
  top: 50%;
  margin-top: -2.5em;
}

.button-label {
  display: inline-block;
  padding: 1em 2em;
  margin: 0.5em;
  cursor: pointer;
  color: red;
  border-radius: 0.25em;
  background: rgb(251, 1, 1);
  box-shadow: 0 3px 10px rgba(0,0,0,0.2), inset 0 -3px 0 rgba(0,0,0,0.22);
  transition: 0.3s;
  user-select: none;
}
.button-label h1 {
    font-size: 1em;
  }
.button-label:hover {
    background: rgb(160, 3, 3);
    color: black;
    box-shadow: 0 3px 10px rgba(0,0,0,0.2), inset 0 -3px 0 rgba(0,0,0,0.32);
  }
.button-label:active {
    transform: translateY(2px);
    box-shadow: 0 3px 10px rgba(0,0,0,0.2), inset 0px -1px 0 rgba(0,0,0,0.22); 
  }

  #numbutton:checked .button-label {
    background: green;
    color: white;
  }
  #numbutton:hover {
      background: green;
      color: green;
    }

  .hidden {
    visibility: none;
  }


  
  .radInput[type=radio] {
    position: absolute;
    visibility: hidden;
    display: none;
  }
  
  .radlabel {
    color: rgb(255, 255, 255);
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
    padding: 1em 1.3em;
    margin-right:5px;
    border-radius: 4px;
    background-color: #717171c6;
    transition: 0.5s;
  }
  .radlabel:hover {
    background-color: #1f883693;
    /* This timing applies on the way OUT */
  transition-timing-function: ease-in-out;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.5);
  /* Quick on the way out */
  transform: translateY(-0.25em);
  }
  
  .radInput[type=radio]:checked + .radlabel{
    color: rgb(255, 255, 255);
    background: rgb(33, 131, 33);
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.5);
    transform: translateY(-0.25em);
  }
  
  .radlabel2 + .radInput2[type=radio] + .radlabel2 {
    border-left: black 3px;
  }

  .radInput2[type=radio] {
    position: absolute;
    visibility: hidden;
    display: none;
  }
  
  .radlabel2 {
    color: rgb(255, 255, 255);
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
    margin: auto;
    padding: 0.4em 0.7em;
    border-radius: 4px;
    background-color: #717171c6;
    transition: 0.5s;
  }
  .radlabel2:hover {
    background-color: #211f8893;
    /* This timing applies on the way OUT */
  transition-timing-function: ease-in-out;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.5);
  /* Quick on the way out */
  transform: translateY(-0.25em);
  }
  
  .radInput2[type=radio]:checked + .radlabel2{
    color: rgb(255, 255, 255);
    background: rgb(33, 40, 131);
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.5);
    transform: translateY(-0.25em);
  }
  
  .radlabel2 + .radInput2[type=radio] + .radlabel2 {
    border-left: black 3px;
  }







  .radio-group {
    border: solid black 3px;
    display: inline-block;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
  }

  .green .rmdp-arrow {
    border: solid var(--rmdp-primary-green);
    border-width: 0 2px 2px 0;
    height:30px !important;
    width: 30px !important;
}

.rmdp-mobile .rmdp-arrow-container {
  height:55px !important;
  width: 55px !important;
  padding: 3px !important;
  margin: 0 14px 10px 10px !important;
}

.rmdp-mobile .rmdp-header, .rmdp-mobile .rmdp-panel-header {
  height: 55px !important;
}



.rmdp-left, .rmdp-right {
  position: absolute;
  padding: 10px !important;
  top: 10%;
  transform: translateY(-50%);
}

.rmdp-calendar {
  height: 540px !important;
  width: 100% !important;
  
}

.rmdp-shadow {
  box-shadow: 0px !important;
}
.rmdp-mobile.rmdp-wrapper {
  box-shadow: unset !important;
  border: 0px !important;
  height: 100% !important;
  width: 100% !important;
  justify-content: center !important;
  display: flex;
}
.rmdp-week, .rmdp-ym {
  display: flex;
  justify-content: space-between;
}
.rmdp-mobile .rmdp-day, .rmdp-mobile .rmdp-week-day {
  height: 60px !important;
  width: 50px !important;
}
.rmdp-day{
  transition: 0.25s;
}

.green .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--rmdp-primary-green);
  box-shadow: 0 0 3px var(--rmdp-shadow-green);
}

.rmdp-day:hover {
  transition-timing-function: ease-in-out;
  /* Quick on the way out */
  transform: translateY(-0.06em);
}

.rmdp-day span {
  border-radius: 10px !important;
  bottom: 3px;
  font-size: 14px;
  left: 3px;
  position: absolute;
  right: 3px;
  top: 3px;
}

:root{
  --rmdp-primary-green: #3d9970 !important;
  --rmdp-secondary-green: rgb(33, 131, 33) !important;
  --rmdp-shadow-green: #87ad92 !important;
  --rmdp-today-green: #6baf89a1 !important;
  --rmdp-hover-green: #19742474 !important;
  --rmdp-deselect-green: #257a544f !important;
  }






/* input stuff */

.inputreg {
	display: flex;
	flex-direction: column-reverse;
	position: relative;
	padding-top: 1.5rem;
  padding-bottom: 1rem;
}
.inputreg+.input {
		margin-top: 1.5rem;
	}


.input-labels {
	color: #8597a3;
	position: absolute;
	top: 1.5rem;
	transition: .35s ease;
}

.phone-input {
	color: #167c27;
  font-size: 0.9rem;
	position: absolute;
	top: 0.3rem;
	transition: .35s ease;
}

.input-fields {
	border: 0;
	z-index: 1;
	background-color: transparent;
	border-bottom: 2px solid #b4b3b3;
	font: inherit;
	font-size: 1.125rem;
	padding: .25rem 0;
}
.input-fields:focus, .input-fields:valid {
	outline: 0;
	border-bottom-color: #167c27;
}
.input-fields:focus + .input-labels, .input-fields:valid + .input-labels {
	color: #167c27;
  font-size: 0.9rem;
	transform: translateY(-1.5rem);
}

.allwrapper {
  max-width:80%;
}


.aboutgrid {
  display: inline-grid;
	grid-template-columns: 1fr 1fr;
  width: 100%;
  padding: 10px;
}
.aboutgrid-mobile {

}

.aboutphoto {
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
    background-image:   url(./aboutphoto.jpg);
    background-size: cover;
    background-position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #ddd;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
    border-radius: 50%;
    height: 300px;
    width: 300px;
}
.aboutphoto p {
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-align: bottom;
  vertical-align: middle;
  margin-top: 220px;
  padding-left: 60px;
  padding-right: 60px;
  background-color: rgb(67, 73, 156);
  font-family: Courier;
  color: rgb(255, 255, 255);
  font-size:20px;
}
.aboutgriditem {
    display: flex;
    flex-direction: column;
    text-align: center;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
}
.aboutphotocontainer {
  display: flex;
  text-align: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
}

.aboutgriditem2 {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-content: center;
}
.aboutphotoa {
  padding-top:3px;
  font-size: 12px;
  font-family: Courier;
  color:white;
}

.aboutgriditem2-mobile {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-content: center;
}

.aboutgriditemfaq {
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: left;
  padding: 40px;
}

.headerh2 {
  font-size: 30px;
  text-align: center;
  color:#004d06;
}


.aboutwrapper {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  justify-content: center;
  text-align: center;
}

.aboutwrapper-mobile {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  justify-content: center;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}


.aboutgrid2 {
  display: inline-grid;
	grid-template-columns: 1fr 1fr;
  width: 100%;
  padding: 10px;
}

.aboutstats {
  color:rgb(17, 97, 73);
  font-size: 23px;
}
.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 84px !important;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  color: green;
  padding: 10px;
}

p2 {
  color:#004d06;
  font-size: 25px;
  font-weight: bold;
  padding-top: 30px;

  padding-bottom: 10px;
}


.sidenav {
  height: auto; /* Full-height: remove this if you want "auto" height */
  width: 95%; /* Set the width of the sidebar */
  position: sticky; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 200px; /* Stay at the top */
  bottom: 20px;
  margin-left: 5%;
  margin-bottom: 20px;
  margin-top: 20px;
  right: 0;
  background-color: #c9c9c9;
  overflow-x: hidden; /* Disable horizontal scroll */
  overflow-y: visible;
  padding-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.151) 0px 1px 3px, rgba(0, 0, 0, 0.046) 0px 1px 2px;
  background-color: rgba(255, 255, 255, 0.801);
  border-radius: 10px;
  justify-content: center;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
}

/* The navigation menu links */
.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
}



.sidebartext {
  list-style-type: none;
  font-size: 17px;
}

.permitgrid {
	display: grid;
	grid-template-columns: 4fr 1fr;
}
.permitgrid-mobile {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
}

.line-2 {
  left: 10%;
  position: sticky;
  border-top: 2px solid rgba(144, 144, 144, 0.432);
  width: 80%;
}

.terms {
  font-size: 11px;
  max-width: 700px;
}
.center-terms {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.center-weather {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.totaltexts {
  font-size: 20px !important;
  opacity: 0;
  transform: translate3d(0, 0, 0);
  /*animation: fadeInAnimation2 ease 3.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;*/
  -webkit-animation: 3s ease 0.5s normal forwards 1 fadeInAnimation;
  animation: 3s ease 0.5s normal forwards 1 fadeInAnimation;
}
.totaltexts-mobile {
  font-size: 12px !important;
  animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.invistext {
  font-size: 20px !important;
  color: rgba(255, 255, 255, 0) !important;
}

.invistext-mobile {
  font-size: 12px !important;
  color: rgba(255, 255, 255, 0) !important;
}



.testimonial-slider {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 100px;
}

.testimonial-slider ul.slider {
  display: block;
  list-style: none;
  padding: 0;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 350px;
  overflow: hidden;
  margin: 0 auto;
}

.testimonial-slider ul.slider li {
   position: absolute;
   display: block;
   width: 100%;
   opacity: 0;
   margin: 0 auto;
}
.testimonial-slider ul.slider li:nth-child(1) {
   opacity: 1;

   -webkit-animation: fade-animation-1 50s infinite;
           animation: fade-animation-1 50s infinite;
}
.testimonial-slider ul.slider li:nth-child(2) {
   -webkit-animation: fade-animation-2 50s infinite;
           animation: fade-animation-2 50s infinite;
}
.testimonial-slider ul.slider li:nth-child(3) {
   -webkit-animation: fade-animation-3 50s infinite;
           animation: fade-animation-3 50s infinite;
}
.testimonial-slider ul.slider li:nth-child(4) {
   -webkit-animation: fade-animation-4 50s infinite;
           animation: fade-animation-4 50s infinite;
}
.testimonial-slider ul.slider li:nth-child(5) {
  -webkit-animation: fade-animation-5 50s infinite;
          animation: fade-animation-5 50s infinite;
}
.testimonial-slider ul.slider li:nth-child(6) {
  -webkit-animation: fade-animation-6 50s infinite;
          animation: fade-animation-6 50s infinite;
}
.testimonial-slider ul.slider li:nth-child(7) {
  -webkit-animation: fade-animation-7 50s infinite;
          animation: fade-animation-7 50s infinite;
}
.testimonial-slider ul.slider li:nth-child(8) {
  -webkit-animation: fade-animation-8 50s infinite;
          animation: fade-animation-8 50s infinite;
}

.testimonial-slider q {
  display: inline-block;
  font: normal 700 25px / 1.25 "Raleway", Helvetica, Arial, Verdana, sans-serif;
  letter-spacing: .5pt;
  text-shadow: 0 5px 10px rgba(0,0,0,.1);
  max-width: 35ch;
}

.testimonial-slider .source {
   margin-top: 25px;
   color: #92ff74;
   font: italic 400 30px / 1.35 "Raleway", Helvetica, Arial, Verdana, sans-serif;
}

/* KEYFRAME DECLARATIONS */
@keyframes fade-animation-1 {
   0%, 12.5%, 100% {
       opacity:0
   }
   1.5%, 10.5% {
       opacity: 1;
   }
}
@keyframes fade-animation-2 {
   0%, 12.5%, 25%, 100% {
       opacity:0
   }
   14%, 23.5% {
       opacity: 1;
   }
}
@keyframes fade-animation-3 {
   0%, 25%, 37.5%, 100% {
       opacity:0
   }
   26.5%, 36% {
       opacity: 1;
   }
}
@keyframes fade-animation-4 {
   0%, 37.5%, 50%, 100% {
       opacity:0
   }
   39%, 48.5% {
       opacity: 1;
   }
}
@keyframes fade-animation-5 {
  0%, 50%, 62.5%, 100% {
      opacity:0
  }
  51.5%, 61% {
      opacity: 1;
  }
}
@keyframes fade-animation-6 {
  0%, 62.5%, 75%, 100% {
      opacity:0
  }
  64%, 73.5% {
      opacity: 1;
  }
}
@keyframes fade-animation-7 {
  0%, 75%, 87.5%, 100% {
      opacity:0
  }
  76.5%, 86% {
      opacity: 1;
  }
}
@keyframes fade-animation-8 {
  0%, 87.5%, 100% {
      opacity:0
  }
  89%, 98.5% {
      opacity: 1;
  }
}

@-webkit-keyframes fade-animation-1 {
   0%, 25%, 100% {
       opacity:0
   }
   3%, 22% {
       opacity: 1;
   }
}
@-webkit-keyframes fade-animation-2 {
   0%, 25%, 50%, 100% {
       opacity:0
   }
   28%, 47% {
       opacity: 1;
   }
}
@-webkit-keyframes fade-animation-3 {
   0%, 50%, 75%, 100% {
       opacity:0
   }
   53%, 72% {
       opacity: 1;
   }
}
@-webkit-keyframes fade-animation-4 {
   0%, 75%, 100% {
       opacity:0
   }
   78%, 97% {
       opacity: 1;
   }
}
@-webkit-keyframes fade-animation-5 {
  0%, 75%, 100% {
      opacity:0
  }
  78%, 97% {
      opacity: 1;
  }
}
@-webkit-keyframes fade-animation-6 {
  0%, 75%, 100% {
      opacity:0
  }
  78%, 97% {
      opacity: 1;
  }
}
@-webkit-keyframes fade-animation-7 {
  0%, 75%, 100% {
      opacity:0
  }
  78%, 97% {
      opacity: 1;
  }
}
@-webkit-keyframes fade-animation-8 {
  0%, 75%, 100% {
      opacity:0
  }
  78%, 97% {
      opacity: 1;
  }
}

.contentmid {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  text-align: center;
}


.trailgrid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}
.trailgridheader {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.imageholder {
  height: 300px;
}

.imageStyle {
  height: 300;
  flex: 1;
  width: null;
}

.cloudsrest {
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
    background-image:   url(./yosemitetitle.jpg);
    background-size: cover;
    background-position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #ddd;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
    width: 100%;
    height: 300px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.cloudsrest p {
  z-index: 1;
  text-align: center;
  vertical-align: middle;
  margin-top: 100px;
  font-family: Courier;
  font-family:Georgia, 'Times New Roman', Times, serif;
  letter-spacing: 0.2rem;
  color: rgb(255, 255, 255);
  font-size:45px;
}

.h1trails {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.overlaytrails {
  position: absolute;
  height: 300px;
  width: 100%;
  z-index: 1;
  background: linear-gradient(0,rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
}

.mapstyle {
  border:none; 
  overflow-y: hidden; 
  background-color:white;  
  width:100%;
  height:430px;
}

.backgroundDiv3{
  margin: auto;
  text-align: center;
  /*background-image:   url(./aniket-deole-M6XC789HLe8-unsplash.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;*/
  width: 70%;
  box-shadow: rgba(0, 0, 0, 0.151) 0px 1px 3px, rgba(0, 0, 0, 0.046) 0px 1px 2px;
  background-color: rgba(255, 255, 255, 0.801);
  padding-bottom:30px;
  border-radius:20px;


}
.maingrid 
{
	display: grid;
	grid-template-columns: 2fr 1fr;
  
}
.testdiv {
  height: 800px;
  width: 100%;
}
.gallery-wrapper {
  width:100%;
  position: relative; 
  display: inline-block;
  overflow:hidden;
  border-top-left-radius:20px;
  border-top-right-radius:20px;
}

.pspecial {
  position:absolute;
  top: 25%;
  left: 30%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  font-family:Georgia, 'Times New Roman', Times, serif;
  letter-spacing: 0.2rem;
  color: rgb(255, 255, 255);
  font-size:35px;
  padding-left: 50px;
  padding-top:30px;
}
.image-gallery-slide img {
    width: 100%;
    height: auto;
    max-height: 300px !important;
    object-fit: cover !important;
    overflow: hidden;
    object-position: center center;
}

.thumbnailcamp {
    border-radius: 20px;
    height: 64x;
    width: 64px;
    object-fit: cover !important;
    overflow: hidden;
    object-position: center center;
}

.fixtext {
  text-align:left;
  padding-left:10%;
  vertical-align: middle;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh !important;
}

.infotrails {
  padding-top:15px;
  border-bottom: 1px solid rgb(114, 114, 114);
  padding-bottom: 5px;
  margin-bottom:20px;
  color:rgba(3, 3, 3, 0.712);
}

.iframeline {
  border-left: 1px solid rgb(114, 114, 114);
  padding-left:10px;
}

.linetrails {
  width: 112px;
  height: 47px;
  border-bottom: 1px solid rgb(110, 110, 110);
  padding-bottom:20px;
}